@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.overflowHide {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.overflowHide::-webkit-scrollbar {
  display: none;
}

/* sidebar.css */
.sidebar-container {
  overflow-y: auto; /* Enable vertical scrollbar */
  height: 100%; /* Set a fixed height for the sidebar container */
}

/* Style the scrollbar */
.sidebar-container::-webkit-scrollbar {
  width: 8px;
}

.sidebar-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners */
}

.sidebar-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Hover color of the scrollbar thumb */
}

.outlet {
  background-color: #f8f8f8;
}

.responsive-table-container {
  overflow-x: auto;
  max-width: 100%;
}

/* Tailwind CSS classes */
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 767px) {
  .modal-sm {
    width: 80%; /* or whatever you find suitable */
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .modal-md {
    width: 50%; /* or whatever you find suitable */
  }
}

/* Use a mobile-first approach */
.table {
  font-size: 14px;
}

/* Hide the S/N and Location columns on small screens */
@media (max-width: 576px) {
  .table td:nth-child(1),
  .table th:nth-child(1),
  .table td:nth-child(4),
  .table th:nth-child(4) {
    display: none;
  }
}

/* Hide the Shipment Date and Destination Country columns on medium screens */
@media (max-width: 768px) {
  .table td:nth-child(2),
  .table th:nth-child(2),
  .table td:nth-child(5),
  .table th:nth-child(5) {
    display: none;
  }
}

/* Reduce the font size on large screens */
@media (min-width: 992px) {
  .table {
    font-size: 12px;
  }
}

